import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi !`}</p>
    <p>{`I'm Kalyan Chakravarthi, you may just call me Kalyan (kaa-lee-yan).`}</p>
    <p>{`Check my recent posts below..`}</p>
    <p>{`You may find more about me in the About section above.. duh!`}</p>
    <p>{`Thank you for dropping by :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      